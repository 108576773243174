import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeCardElement } from '@stripe/stripe-js';
import Payment from '../../API/payment.requests';
import { Spinner } from '../Spinner';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { User, useUser } from '../../contexts/user.context.tsx';
import { PaymentViewUIProps } from './PaymentView';
import { AxiosError } from 'axios';
import { User as AuthUser } from '@firebase/auth';
import { useAuth } from '../../contexts/auth.context.tsx';
import { useActiveMainPlan } from '../../hooks/plans.hooks.ts';
import { PlanT, defaultPlanId, getPlan } from '../../utils/plans.helpers.tsx';
import { usePostHogTracking } from '../../hooks/usePostHogTracking.js';

export default function StripeForm({
  onSuccess,
  payButtonLabel = 'Pay',
  doRequest,
}: PaymentViewUIProps & {
  doRequest: (
    authUser: AuthUser,
    user: User,
    stripe: Stripe,
    customerId: string,
    card: StripeCardElement
  ) => Promise<void>;
}) {
  const { currentUser } = useAuth();
  const { user } = useUser();

  const stripe = useStripe();
  const elements = useElements();

  const [isSuccess, setIsSuccess] = useState(false);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [isCardReady, setIsCardReady] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [customerId, setCustomerId] = useState('');
  const isLoading = Boolean(!stripe || !elements || isSendingRequest || !customerId);
  const activePlan = useActiveMainPlan();
  const [searchParams] = useSearchParams();
  const planId: string = searchParams.get('plan') ?? defaultPlanId;
  const targetPlan: PlanT = getPlan(planId) ?? getPlan(defaultPlanId)!;
  const { posthogCaptureEvent } = usePostHogTracking();

  useEffect(() => {
    if (user) {
      Payment.createCustomer({
        userId: user.id,
        email: user.email,
      }).then(({ customerId }) => {
        setCustomerId(customerId);
      });
    }
  }, [user]);

  /**
   * Removes extra `Request req_XXXXXXXXX:` string from start of Stripe's error messages.
   */
  function formatErrorMessage(message: string) {
    if (!message || typeof message !== 'string') return null;

    const requiresFormatting = message.startsWith('Request req_');
    if (!requiresFormatting) return message; // No formatting needed.

    const splits = message.split(': ');
    splits.shift(); // Remove the first element.
    return splits.join(': ');
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!currentUser) return;
    if (!user) return;
    if (!stripe || !elements) return;
    if (!isCardReady) return;
    if (isLoading) return;
    if (!CardElement) return;

    const card = elements.getElement(CardElement);
    if (!card) return;

    setIsSendingRequest(true);
    try {
      await doRequest(currentUser, user, stripe, customerId, card);

      onSuccess();
      setIsSuccess(true);
      posthogCaptureEvent('user_billing_event', {
        userId: currentUser?.uid,
        oldPlan: activePlan ?? 'free',
        newPlan: targetPlan?.name?.toLowerCase(),
      });
    } catch (err: unknown) {
      console.error(err);
      let message = null;
      if (err instanceof AxiosError && err.response?.data?.error?.message) {
        message = err.response.data.error.message;
      }
      message = formatErrorMessage(message ?? 'Something went wrong.');
      setError(message);
    } finally {
      setIsSendingRequest(false);
    }
  };

  return (
    <div>
      {!isSuccess && (
        <form id="payment-form" onSubmit={handleSubmit} className="rounded-lg">
          <div className="mb-4 [&>*]:rounded-lg [&>*]:border [&>*]:border-gray-700/60 [&>*]:bg-white/5 [&>*]:px-3 [&>*]:py-2 [&>*]:outline-0">
            <CardElement
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    backgroundColor: 'transparent',
                    color: 'white',
                    fontSize: '16px',
                    letterSpacing: '0.025em',
                    fontFamily: 'monospace',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#EF4444',
                  },
                },
              }}
              onChange={(event) => {
                if (event && event.error && event.error.message) {
                  setError(event.error?.message);
                } else {
                  setError(null);
                }
                setIsCardReady(event.complete);
              }}
            />
          </div>

          <button
            type="submit"
            className="mt-4 flex items-center justify-center rounded-lg bg-primary  text-neutral-50 w-full  rounded-b-lg border-0 px-4 py-3 text-base font-semibold cursor-pointer  transition duration-200 ease-shadow-md hover:filter-contrast-115% disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isLoading || error || !isCardReady ? true : false}
          >
            {isLoading && <Spinner label="Loading payment form..." />}
            {!isLoading && <span>{payButtonLabel}</span>}
          </button>
        </form>
      )}

      {error && !isSuccess && (
        <p className="text-center text-red-600 text-sm mt-3" role="alert">
          {error}
        </p>
      )}

      {isSuccess && (
        <div className="max-w-380 mx-auto flex flex-col items-center rounded-lg bg-neutral-50 p-12">
          <CheckCircleIcon className="text-3xl text-primary" />

          <h4 className="text-2xl font-bold">Thank you for your purchase!</h4>

          <p className="mt-4 text-center text-sm font-normal">
            We’ve sent you a receipt on your registered email with us. If you need help, please reach out to us at{' '}
            <a href="mailto:support@play.ht" className="text-primary">
              support@play.ht
            </a>
            .
          </p>
        </div>
      )}

      {!isSuccess && (
        <>
          <div className="flex items-center justify-center">
            <img
              src="https://playtht-website-assets.s3.amazonaws.com/img/icons/common/credit-cards.png"
              className="w-44 p-4"
            />
            <img
              src="https://playtht-website-assets.s3.amazonaws.com/img/product/powered_by_stripe.svg"
              className="w-24"
            />
          </div>

          <p className="text-center" id="security">
            <small>Your payment information is safely encrypted and stored by our partner Stripe.</small>
          </p>
        </>
      )}
    </div>
  );
}
