import { usePostHog } from 'posthog-js/react';
import { CONFIG } from '../config';

export function usePostHogTracking() {
  const posthog = usePostHog();

  const posthogCaptureEvent = (event, metadata) => {
    if (!CONFIG.isProduction || !posthog) return;
    posthog?.capture(event, { ...metadata });
  };

  return {
    posthogCaptureEvent,
  };
}
