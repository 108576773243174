import { useEffect } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { CONFIG } from '../../config.ts';

export const PHProvider = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    if (CONFIG.isProduction) {
      posthog.init(CONFIG.postHogKey, {
        api_host: CONFIG.postHogHost,
        person_profiles: 'identified_only',
      });
    }
  }, []);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};
