import { useEffect, useCallback, useRef, useState } from 'react';
import { useAuth } from '../../contexts/auth.context.tsx';
import { useNavigate } from 'react-router-dom';
import { AuthError } from '@firebase/auth';
import { formatFirebaseError } from '../../utils/formatFirebaseError.ts';
import { Button } from '../../components/Button.tsx';
import { PlayhtLogo } from '../../components/PlayhtLogo.tsx';
import { Divider } from '../../components/Divider.tsx';
import { TextField } from '../../components/TextField.tsx';
import { TextLink } from '../../components/TextLink.tsx';
import { Transition } from '@headlessui/react';
import { CONFIG } from '../../config.ts';

export function LoginPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resettingPassword, setResettingPassword] = useState(false);
  const [error, setError] = useState<AuthError | null>(null);
  const [message, setMessage] = useState<string>();
  const [loading, setLoading] = useState(false);
  const hasSetToVisible = useRef(false);

  const handleSubmit = useCallback(async () => {
    try {
      setError(null);
      setLoading(true);
      await auth.logIn(email, password);
      navigate('/');
    } catch (error) {
      setError(error as AuthError);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [auth, email, password, navigate]);

  const resetPassword = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      await auth.resetPassword(email);
      setMessage(`Follow the instructions sent to ${email} to recover your password`);
    } catch (error) {
      setError(error as AuthError);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [auth, email]);

  const handleGoogleLogin = useCallback(async () => {
    try {
      setError(null);
      await auth.loginWithGoogle();
      navigate('/');
    } catch (error) {
      setError(error as AuthError);
      console.error(error);
    }
  }, [auth, navigate]);

  useEffect(() => {
    if (hasSetToVisible.current) return;
    setVisible(true);
    hasSetToVisible.current = true;
  }, []);

  return (
    <div className="flex min-h-full flex-1 flex-col content-center justify-center px-6 py-12 transition desktop:px-8 desktop:pb-32">
      <Transition show={visible} enter="transition-opacity duration-1000" enterFrom="opacity-0" enterTo="opacity-100">
        <div className="tablet:mx-auto tablet:w-full tablet:max-w-sm">
          <PlayhtLogo className="mx-auto h-11" />
        </div>
        <div className="mt-10 tablet:mx-auto tablet:w-full tablet:max-w-sm">
          <form className="space-y-12" onSubmit={(e) => e.preventDefault()}>
            {resettingPassword && (
              <div>
                <h3 className="text-xl font-bold mb-2">Recover Password</h3>
                <p>Get instructions sent to this email that explain how to reset your password</p>
              </div>
            )}
            <div className="space-y-5">
              <TextField
                name="email"
                label="Email"
                type="email"
                autoComplete="email"
                value={email}
                placeholder="Enter your email"
                width="auto"
                onChange={setEmail}
              />
              {!resettingPassword && (
                <TextField
                  name="password"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  placeholder="Enter your password"
                  width="auto"
                  showOption
                  onChange={setPassword}
                />
              )}
              <div>
                {error && (
                  <div className="text-error">
                    <span>{formatFirebaseError(error)}</span>
                  </div>
                )}
                {message && (
                  <div>
                    <span>{message}</span>
                  </div>
                )}
              </div>
            </div>
            <Button
              type="submit"
              intent="primary"
              width="full"
              loading={loading}
              onClick={() => {
                resettingPassword ? resetPassword() : handleSubmit();
              }}
            >
              {resettingPassword ? 'Reset Password' : 'Sign In'}
            </Button>
            {!resettingPassword && (
              <div className="text-center !mt-4">
                <TextLink
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setResettingPassword(true);
                  }}
                >
                  Trouble signing in?
                </TextLink>
              </div>
            )}
            <Divider text="or" />
            <Button intent="google" width="full" onClick={handleGoogleLogin}>
              Log in with Google
            </Button>
            <Divider />
          </form>
          <p className="mt-6 text-center">
            Don't have an account?{' '}
            <TextLink to={`${CONFIG.playWebsiteBaseUrl}/signup`} className="ml-1.5">
              Sign Up Now
            </TextLink>
          </p>
        </div>
      </Transition>
    </div>
  );
}
