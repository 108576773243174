import { RouterProvider } from 'react-router-dom';
import { router } from './router.tsx';
import { errorsTracker } from './infra/errors/errorsTracker.ts';
import { eventsTracker } from './infra/analytics/eventsTracker.ts';
import { useCalledOnce } from './hooks/useCalledOnce.ts';

export function App() {
  useCalledOnce(() => {
    errorsTracker.init();
    eventsTracker.init();
  });
  return <RouterProvider router={router} />;
}
