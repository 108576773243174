import { Dispatch, Fragment, ReactNode, SetStateAction, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { XMarkIcon } from '@heroicons/react/24/outline';

export function Modal({
  open,
  setOpen,
  children,
  maxWidth,
  className,
  persistent = false,
  showXToClose = false, // TODO: change to true (it's false now because we started using without it; we need to check the impact in all usages)
}: {
  children: ReactNode;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  maxWidth?: string;
  className?: string;
  persistent?: boolean;
  showXToClose?: boolean;
}) {
  const onCloseModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={persistent ? () => null : setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-primary-950/70 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6 max-tablet:p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
              enterTo="opacity-100 translate-y-0 tablet:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
              leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  'relative max-h-full p-8 max-tablet:p-4 transform overflow-hidden rounded-xl bg-neutral-50 ring-1 ring-neutral-800/75 transition-all dark:bg-neutral-950',
                  className
                )}
                style={maxWidth ? { maxWidth, width: '100%' } : {}}
              >
                <div className="relative">
                  {showXToClose && !persistent ? (
                    <div className="absolute -top-1 -right-1 p-1 cursor-pointer" onClick={onCloseModal}>
                      <XMarkIcon className="h-6 w-6 text-neutral-50" aria-hidden="true" />
                    </div>
                  ) : null}
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
