/**
 * A custom React hook that ensures a callback function is called only once
 * during the component's lifecycle.
 *
 * @param callback - A function that will be executed only once when the component mounts.
 *
 * @example
 * // Usage inside a functional component:
 * useCalledOnce(() => {
 *   console.log('This runs only once when the component mounts.');
 * });
 *
 * @remarks
 * - This hook uses `useRef` to keep track of whether the callback has already been called.
 * - `useEffect` is used to ensure the callback is called only when the component mounts.
 */
import { useRef, useEffect } from 'react';

// Type definition for the callback function
type Callback = () => void;

/**
 * useCalledOnce hook implementation
 */
export function useCalledOnce(callback: Callback): void {
  // useRef to store whether the callback has been called
  const hasBeenCalled = useRef<boolean>(false);

  useEffect(() => {
    // Check if the callback has been called before
    if (!hasBeenCalled.current) {
      callback(); // Call the callback function
      hasBeenCalled.current = true; // Mark as called
    }
  }, [callback]); // Dependency array ensures the hook runs with the same callback reference
}
