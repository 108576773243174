import { Menu, Transition } from '@headlessui/react';
import { Fragment, MouseEvent, useEffect, useState, useRef } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { clsx } from 'clsx';
import { Model, FileModels } from '../../constants/model';
import { CONFIG } from '../../config';
import { UserAgentUtils } from '../../utils/UserAgentUtils';
import { ListItem } from './ListItem';
import { Drawer } from '../Drawer';
import { CreateAndOpenFileParams } from '../../hooks/files.hooks';
import { renderLoading } from '../Button';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

const isMobile = UserAgentUtils.isOnMobile();

export default function NewFilePicker({
  isCreatingFile,
  createAndOpenFile,
}: {
  isCreatingFile: boolean;
  createAndOpenFile: (params?: CreateAndOpenFileParams) => void;
}) {
  const { featureFlags, featureFlagsLoading } = useFeatureFlags();

  const [enabledFileModels, setEnabledFileModels] = useState(FileModels);
  const [selectedModel, setSelectedModel] = useState<Model>(enabledFileModels[0]);
  const [open, setOpen] = useState(false);

  const hasRunEffect = useRef(false);

  useEffect(() => {
    if (featureFlagsLoading || hasRunEffect.current) return;

    const enableDialogueVoiceModel = featureFlags.ENABLE_DIALOGUE_VOICE_MODEL;
    const updatedFileModels = enableDialogueVoiceModel
      ? FileModels
      : FileModels.filter((model) => model.id !== 'PlayDialog');

    setEnabledFileModels(updatedFileModels);
    setSelectedModel(updatedFileModels[0]);
    hasRunEffect.current = true;
  }, [featureFlags, featureFlagsLoading]);

  const onSelectModel = (model: Model) => {
    if (model.id === 'Standard') {
      window.open(CONFIG.playDashboardBaseUrl, '_blank');
    } else {
      setSelectedModel(model);
    }

    if (isMobile) {
      setOpen(false);
    }

    // create new file if model is not standard
    if (model.id !== 'Standard') createAndOpenFile({ model: model.id });
  };

  const onNewFileClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    createAndOpenFile({ model: selectedModel.id });
  };

  const newFileButton = (
    <button
      disabled={isCreatingFile}
      className="duration-200 ease-out flex items-center bg-gray-700 py-4 pl-[15px] pr-[17px] justify-center gap-1 rounded-l-2xl flex-1 disabled:opacity-50 hover:opacity-90"
      onClick={onNewFileClick}
    >
      {isCreatingFile ? renderLoading() : <PlusIcon className="w-5 h-5 text-white" />}
      <span className="font-semibold text-white text-sm leading-[20px] whitespace-nowrap">New File</span>
    </button>
  );

  const modelSelectorButton = (
    <button
      className="duration-200 ease-out bg-gray-700 py-1 px-2 min-w-[63px] flex gap-1 items-center rounded-r-2xl font-semibold disabled:opacity-50 hover:opacity-90"
      disabled={isCreatingFile}
    >
      <span className="items-center text-[12px] flex gap-[2px] px-2 rounded-full bg-black">
        <span className="font-semibold text-white leading-[20px] whitespace-nowrap">{selectedModel.shortName}</span>
        <ChevronDownIcon className="w-[10px] h-[10px] text-white" aria-hidden="true" />
      </span>
    </button>
  );

  return isMobile ? (
    <div className={clsx('w-full inline-block text-left rounded-2xl duration-200 ease-out flex-1 px-6')}>
      <div className="flex gap-[1px] flex-1 w-full items-stretch">
        {newFileButton}
        <Drawer title="Choose Model" trigger={modelSelectorButton} open={open} setOpen={setOpen}>
          <div className="px-2 py-4">
            {enabledFileModels.map((model) => (
              <ListItem key={model.id} onClick={onSelectModel} model={model} selectedModel={selectedModel} />
            ))}
          </div>
        </Drawer>
      </div>
    </div>
  ) : (
    <div className="px-6">
      <Menu as="div" className="relative flex w-full z-10">
        {({ open }) => (
          <>
            <div
              className={clsx(
                'w-full inline-block text-left rounded-2xl duration-200 ease-out',
                open && ' shadow-[0px_0px_0px_2px_rgba(180,253,131,0.5)]'
              )}
            >
              <Menu.Button as="div" className="flex gap-[1px] flex-1 w-full items-stretch">
                {newFileButton}
                {modelSelectorButton}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute top-full left-0 mt-[6px] w-[360px] divide-y divide-gray-100 origin-top-right focus:outline-none bg-gray-900 rounded-2xl border border-[rgba(255,255,255,0.2)]">
                <div className="px-2 py-4">
                  <h2 className="px-2 text-[12px] leading-5 font-semibold text-white">Choose Model</h2>
                  {enabledFileModels.map((model) => (
                    <Menu.Item key={model.id}>
                      <ListItem onClick={onSelectModel} model={model} selectedModel={selectedModel} />
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}
