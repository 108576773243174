import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../utils/firebase';
import {
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateEmail as updateEmailFirebase,
  updatePassword as updatePasswordFirebase,
  updateProfile as updateProfileFirebase,
} from 'firebase/auth';
import { User, UserCredential } from '@firebase/auth';
import { saveUserToRealtimeDatabase } from '../API/user.requests';
import { eventsTracker, MixpanelEventType } from '../infra/analytics/eventsTracker.ts';
import { generateUserDataFromFirebaseUserForTracking } from '../utils/user.helpers';

interface AuthContextProps {
  loading: boolean;
  currentUser: User | null;
  signUp: (name: string, email: string, password: string) => Promise<UserCredential>;
  logIn: (email: string, password: string) => Promise<UserCredential>;
  loginWithGoogle: () => Promise<UserCredential>;
  logOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateEmail: (email: string) => Promise<void>;
  updatePassword: (password: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextProps>({
  loading: true,
  currentUser: null,
  signUp: () => Promise.resolve({} as UserCredential),
  logIn: () => Promise.resolve({} as UserCredential),
  loginWithGoogle: () => Promise.resolve({} as UserCredential),
  logOut: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  updateEmail: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
});

export const AuthProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: User | null) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const signUp = async (name: string, email: string, password: string) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user as User;
    await updateProfileFirebase(user, { displayName: name });
    await sendEmailVerification(user);
    await setupProfile(userCredential);
    return userCredential;
  };

  const logIn = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const loginWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential?.accessToken;
      // The signed-in user info.
      // const user = result.user;
      await setupProfile(result);
      return result;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // Handle Errors here.
      // const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      // const email = error.customData.email;
      // The AuthCredential type that was used.
      // const credential_1 = GoogleAuthProvider.credentialFromError(error);

      throw new Error(errorMessage);
    }
  };

  const logOut = () => {
    return auth.signOut();
  };

  const resetPassword = (email: string) => {
    return sendPasswordResetEmail(auth, email);
  };

  const updateEmail = async (email: string) => {
    if (currentUser) return updateEmailFirebase(currentUser, email);
  };

  const updatePassword = async (password: string) => {
    if (currentUser) return updatePasswordFirebase(currentUser, password);
  };

  async function setupProfile(credentials: UserCredential) {
    const info = getAdditionalUserInfo(credentials);

    if (info?.isNewUser) {
      localStorage.setItem('userStatus', 'newUser');
    } else {
      return;
    }

    if (info.providerId === 'password') {
      eventsTracker.sendMixpanelEvent(
        MixpanelEventType.SigupWithEmail,
        generateUserDataFromFirebaseUserForTracking(credentials.user)
      );
    } else if (info.providerId === 'google.com') {
      eventsTracker.sendMixpanelEvent(
        MixpanelEventType.SigupWithGoogle,
        generateUserDataFromFirebaseUserForTracking(credentials.user)
      );
    }
    const idToken = await auth.currentUser?.getIdToken();

    return saveUserToRealtimeDatabase(
      {
        id: credentials.user.uid,
        email: credentials.user.email,
        name: credentials.user.displayName,
        imageUrl: credentials.user.photoURL,
        refreshToken: credentials.user.refreshToken,
        providerId: credentials.user.providerData[0].providerId,
        source: 'roost',
      },
      idToken as string
    );
  }

  const value: AuthContextProps = {
    loading,
    currentUser,
    signUp,
    logIn,
    loginWithGoogle,
    logOut,
    resetPassword,
    updateEmail,
    updatePassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
