import { useEffect, useCallback, useState } from 'react';
import { Modal } from '../Modal';
import ArrowRightIcon from '../../assets/arrow-right-icon.svg';
import { AnnouncementsJournalSteps } from './AnnouncementsJournalSteps';
import { ANNOUNCEMENTS } from './announcements';
import { useUser } from '../../contexts/user.context';

export function Play3AnnouncementModal() {
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  const isUserOnboarded = [user?.role, user?.type, user?.usecase].some(Boolean);
  const isNewUser = localStorage.getItem('userStatus') === 'isNew';
  const announcements = ANNOUNCEMENTS.map((announcement) => announcement);

  const close = useCallback(() => {
    localStorage.removeItem('userStatus');
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (isUserOnboarded && isNewUser) {
      setOpen(true);
    }
  }, [isUserOnboarded, isNewUser]);

  return (
    <>
      <button
        className="flex items-center w-full bg-gray-900 border-gray-800 p-3 rounded-lg justify-between mb-2 hover:opacity-90 duration-200 ease-out"
        onClick={() => setOpen(true)}
      >
        <p className="flex items-center">
          <span className="leading-[20px] text-xs text-white">Meet Play 3.0</span>
          <span className="text-[10px] font-semibold text-black bg-primary-green-600 rounded-full px-2 ml-1">NEW</span>
        </p>
        <img src={ArrowRightIcon} alt="Discord" className="w-5 h-5" />
      </button>
      <Modal open={open} setOpen={setOpen}>
        <AnnouncementsJournalSteps announcements={announcements} onFinish={close} />
      </Modal>
    </>
  );
}
