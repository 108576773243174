import { useCallback } from 'react';
import { VoiceCloningConsentCheckbox } from '../../pages/VoiceCloning/VoiceCloningConsentCheckbox';
import { Button } from '../../__playrepo/ui-components/src/Button';
import { UploadedFile } from './UploadedFile';
import { CloneVoiceParams } from '../../API/voiceCloning.requests';
import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { DropzoneFile } from '../../__playrepo/ui-components/src/FilesDropzone';
import GenderToggle from '../../pages/VoiceCloning/GenderToggle';
import { VoiceGender } from '../../__playrepo/play-voice-domain/src/Voice';

interface FinishInstantCloneProps {
  params: Omit<CloneVoiceParams, 'voiceId'>;
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onGenderChange: (value: VoiceGender) => void;
  uploadedSampleFile: DropzoneFile[];
  onFileDelete: () => void;
  consent: boolean;
  setConsent: Dispatch<SetStateAction<boolean>>;
  isGeneratingVoiceCloneFiles: boolean;
  isFinishingVoiceCloning: boolean;
  handleSendRequest: () => void;
}

const FinishInstantClone: FC<FinishInstantCloneProps> = ({
  params,
  onNameChange,
  onGenderChange,
  uploadedSampleFile,
  onFileDelete,
  consent,
  setConsent,
  isGeneratingVoiceCloneFiles,
  isFinishingVoiceCloning,
  handleSendRequest,
}) => {
  const onToggleGender = useCallback(
    (value: VoiceGender) => {
      onGenderChange(value);
    },
    [onGenderChange]
  );

  const onSetConsent = useCallback(
    (value: boolean) => {
      setConsent(value);
    },
    [setConsent]
  );

  return (
    <div className="flex flex-col flex-1">
      <input
        value={params.name}
        onChange={onNameChange}
        placeholder="Untitled Voice Clone"
        className="mt-8 mb-4 w-full text-[16px] leading-none p-4 bg-gray-800 text-white placeholder:text-gray-600 border-2 rounded-2xl border-[rgba(180,253,131,0.2)]"
      />

      {uploadedSampleFile.length ? (
        <div className="flex flex-col gap-2 mb-4">
          {uploadedSampleFile.map((sample) => (
            <UploadedFile sample={sample} key={sample.file.name} onFileDelete={onFileDelete} />
          ))}
        </div>
      ) : null}

      <GenderToggle value={params.gender} onChange={onToggleGender} />

      <VoiceCloningConsentCheckbox consent={consent} setConsent={onSetConsent} />

      <Button
        intent="cta"
        className="w-full"
        disabled={!consent || isGeneratingVoiceCloneFiles || isFinishingVoiceCloning}
        onClick={handleSendRequest}
        loading={isGeneratingVoiceCloneFiles || isFinishingVoiceCloning}
      >
        {isFinishingVoiceCloning ? 'Cloning Voice...' : 'Finish'}
      </Button>
    </div>
  );
};

export default FinishInstantClone;
