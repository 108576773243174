import * as util from 'util';

export function capitalizeFirstChar(str: string) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function copyTextToClipboard(text: string) {
  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

// Stringifies anything even when they have circular references
export function stringifySafely(v: unknown) {
  try {
    return JSON.stringify(v);
  } catch (e) {
    return util.inspect(v);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stringifySafelyEachProp(obj?: Record<string, any> | ArrayLike<any>) {
  if (!obj) return obj;
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, stringifySafely(v)]));
}

export function getWordCount(text: string) {
  return text
    .trim()
    .split(/\s+/)
    .filter((word) => word !== '').length;
}
