import { useState, Dispatch, SetStateAction, ChangeEvent, useEffect } from 'react';
import { useErrorNotification } from '../../hooks/useErrorNotification';
import { useUploadFile } from './useUploadFile';
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
  useNotifications,
} from '../../__playrepo/ui-components/src/NotificationSnackbar';
import {
  useClonedVoicesAndRequests,
  useCloneHFVoice,
  useCreateVoiceClone,
  useGenerateVoiceCloneFiles,
} from '../../hooks/voiceCloning.hooks';
import { useAudioRecorder } from '../../hooks/audioRecorder.hook';
import {
  CloneVoiceParams,
  getPresignedURL,
  GuideClipT,
  LanguageSchema,
  uploadToS3,
} from '../../API/voiceCloning.requests';
import { DropzoneFile } from '../../__playrepo/ui-components/src/FilesDropzone';
import {
  useActiveMainPlan,
  useUserReachedHFCloningLimit,
  useUserReachedInstantCloningLimit,
} from '../../hooks/plans.hooks';
import { usePostHogTracking } from '../../hooks/usePostHogTracking';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth.context';
import { hasMinimumClippings } from '../AudioClipper';
import { ModalType } from '../../pages/VoiceCloning/VCListingPage';
import { VoiceCloningModal } from './CreateVoiceCloneWizard';
import { useCreateAndOpenFile } from '../../hooks/files.hooks';
import { VoiceGender } from '../../__playrepo/play-voice-domain/src/Voice';
import { z } from 'zod';
export enum STEP {
  VOICE_CLONE_TYPE = 'voiceCloneType',
  INSTANT_CLONE_METHOD = 'instantCloneMethod',
  HIGH_FIDELITY_CLONE_METHOD = 'highFidelityCloneMethod',
  HIGH_FIDELITY_CLONE_FINISH = 'highFidelityCloneFinish',
  RECORD = 'record',
  RECORDING = 'recording',
  INSTANT_CLONE_FINISH = 'instantCloneFinish',
  INPUT_LANGUAGE = 'inputLanguage',
}

type UseCreateVoiceCloneWizard = () => {
  ar_epochs: number;
  diff_epochs: number;
  speakerName: string;
  step: STEP;
  modal: ModalType;
  hasMultipleSpeakers: boolean;
  setClippings: Dispatch<SetStateAction<GuideClipT[] | undefined>>;
  clippings: GuideClipT[] | undefined;
  setHasMultipleSpeakers: Dispatch<SetStateAction<boolean>>;
  setSpeakerName: Dispatch<SetStateAction<string>>;
  setArEpochs: Dispatch<SetStateAction<number>>;
  setDiffEpochs: Dispatch<SetStateAction<number>>;
  uploadedHfFiles: UploadedFile[];
  isRecording: boolean;
  isSubmitting: boolean;
  onPrevious: () => void;
  onRecord: () => void;
  onInstant: () => void;
  uploadedSampleFile: DropzoneFile[];
  uploadFile: (file: File) => void;
  updateParams: (newParams: Partial<CloneVoiceParams>) => void;
  deleteFile: () => void;
  onFileDelete: () => void;
  onFilesSelected: (files: File[]) => void;
  consent: boolean;
  setConsent: Dispatch<SetStateAction<boolean>>;
  isGeneratingVoiceCloneFiles: boolean;
  handleSendRequest: () => void;
  isFinishingVoiceCloning: boolean;
  params: Omit<CloneVoiceParams, 'voiceId'>;
  closeModal: () => void;
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onGenderChange: (value: VoiceGender) => void;
  openModal: () => void;
  isDisabled: boolean;
  onStartRecording: () => void;
  onStop: () => void;
  onRestart: () => void;
  recordingTime: number;
  audioStream: MediaStream | null;
  onHighFidelity: () => void;
  uploadHfFile: (files: File[]) => Promise<void>;
  handleDeleteAudio: (index: number) => void;
  submit: (params: {
    audioUrls: string[];
    speakerName: string;
    hasMultipleSpeakers: boolean;
    clippings?: GuideClipT[];
    ar_epochs: number;
    diff_epochs: number;
  }) => void;
  getOpenSetter: (m: ModalType) => (open: SetStateAction<boolean>) => void;
  voiceCloningType: 'instant' | 'hf';
  hfCloningExceeded: boolean;
  instantCloningExceeded: boolean;
  onChangeLanguage: (language: Language) => void;
  onDefineLang: () => void;
};

export type Language = z.infer<typeof LanguageSchema>;

export const languages: Language[] = LanguageSchema.options;

const defaultParams: Omit<CloneVoiceParams, 'voiceId'> = {
  name: '',
  description: '',
  originalSample: '',
  originalSampleFileName: '',
  gender: 'male',
  accent: null,
  loudness: null,
  style: null,
  texture: null,
  customAttributes: {},
  language: languages[0],
};

export type UploadedFile = { file: File; url: string; name: string; progress: number };

const useCreateVoiceCloneWizard: UseCreateVoiceCloneWizard = () => {
  const [step, setStep] = useState(STEP.VOICE_CLONE_TYPE);
  const notifyError = useErrorNotification();
  const [params, setParams] = useState<Omit<CloneVoiceParams, 'voiceId'>>(defaultParams);
  const [consent, setConsent] = useState(false);
  const updateParams = (newParams: Partial<CloneVoiceParams>) => setParams((p) => ({ ...p, ...newParams }));
  const { setNotification } = useNotifications();
  const close = VoiceCloningModal.useStore((state) => state.close);
  const open = VoiceCloningModal.useStore((state) => state.open);
  const { createAndOpenFile } = useCreateAndOpenFile();
  const { currentUser: user } = useAuth();
  const { posthogCaptureEvent } = usePostHogTracking();
  const { mutate: finishVoiceCloning, isLoading: isFinishingVoiceCloning } = useCreateVoiceClone({
    onError: () => setNotification({ status: 'error', message: 'Failed to clone voice.' }),
    onSuccess: (voiceId) => {
      setNotification({
        status: 'success',
        message: 'Voice cloned successfully!',
        content: (
          <button
            onClick={() => createAndOpenFile({ model: 'Play3.0', defaultVoice: voiceId })}
            className="underline text-[14px] leading-[20px] font-medium"
          >
            Use it
          </button>
        ),
      });
      closeModal();
      posthogCaptureEvent('voice_clone', { userId: user?.uid });
    },
  });
  const { clonedVoices, vcRequestsInFlight } = useClonedVoicesAndRequests();

  const hfCloningExceeded = useUserReachedHFCloningLimit(clonedVoices, vcRequestsInFlight);

  const instantCloningExceeded = useUserReachedInstantCloningLimit(clonedVoices);
  const [modal, setModal] = useState<ModalType>(null);
  const getOpenSetter = (m: ModalType) => (open: SetStateAction<boolean>) => setModal(open ? m : null);
  const [voiceCloningType, setVoiceCloningType] = useState<'instant' | 'hf'>('instant');

  const [isUploadingHfFile, setIsUploadingHfFile] = useState(false);
  const [uploadedHfFiles, setUploadedHfFiles] = useState<UploadedFile[]>([]);
  const [speakerName, setSpeakerName] = useState('');
  const [hasMultipleSpeakers, setHasMultipleSpeakers] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const activePlan = useActiveMainPlan();
  const [clippings, setClippings] = useState<GuideClipT[] | undefined>(undefined);

  const [ar_epochs, setArEpochs] = useState<number>(40);
  const [diff_epochs, setDiffEpochs] = useState<number>(100);
  const navigate = useNavigate();

  const openModal = () => {
    open();
  };

  const handleDeleteAudio = (index: number) => {
    setUploadedHfFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const {
    mutate: generateVoiceCloneFiles,
    isLoading: isGeneratingVoiceCloneFiles,
    data: voiceCloneFiles,
  } = useGenerateVoiceCloneFiles({
    onError: () => {
      setNotification({ status: 'error', message: 'Failed to generate voice clone files.' });
    },
  });

  const { uploadFile, uploadedSampleFile, deleteFile } = useUploadFile({
    onError: notifyError,
    onSuccess: ({ url, fileName }) => {
      updateParams({ originalSample: url, originalSampleFileName: fileName });
      generateVoiceCloneFiles(url);

      setStep(STEP.INSTANT_CLONE_FINISH);
    },
  });

  const onFilesSelected = (files: File[]) => {
    uploadFile(files[0]);
    setStep(STEP.INSTANT_CLONE_FINISH);
  };

  const onDefineLang = () => {
    setStep(voiceCloningType === 'instant' ? STEP.INSTANT_CLONE_METHOD : STEP.HIGH_FIDELITY_CLONE_METHOD);
  };

  const { isRecording, recordingTime, startRecording, stopRecording, audioStream } = useAudioRecorder(onFilesSelected);

  const closeModal = () => {
    close('onGiveUp');
    setParams(defaultParams);
    setStep(STEP.VOICE_CLONE_TYPE);
    deleteFile();
    stopRecording();
    setUploadedHfFiles([]);
    setConsent(false);
    setSpeakerName('');
  };

  const { isLoading: isSubmitting, mutate: submit } = useCloneHFVoice(closeModal);

  const onInstant = () => {
    setVoiceCloningType('instant');
    if (!activePlan && instantCloningExceeded) {
      navigate('/studio/pricing');
      closeModal();
    } else if (instantCloningExceeded) {
      setModal('upgrade');
    } else {
      setStep(STEP.INPUT_LANGUAGE);
    }
  };

  const onHighFidelity = () => {
    setVoiceCloningType('hf');

    if (!activePlan) {
      navigate('/studio/pricing');
      closeModal();
    } else if (hfCloningExceeded) {
      setModal('upgrade');
    } else {
      setStep(STEP.INPUT_LANGUAGE);
    }
  };

  const onFileDelete = () => {
    updateParams({ originalSample: '', originalSampleFileName: '' });
    deleteFile();
    setStep(STEP.INSTANT_CLONE_METHOD);
  };

  const uploadHfFile = async (files: File[]): Promise<void> => {
    if (!files.length) {
      setNotification({
        message: 'Please upload an audio file',
        status: NOTIFICATION_TYPE_ERROR,
      });
      return;
    }
    if (!user) {
      return;
    }

    setStep(STEP.HIGH_FIDELITY_CLONE_FINISH);
    setIsUploadingHfFile(true);

    setUploadedHfFiles(files.map((f) => ({ file: f, url: '', name: f.name, progress: 0 })));

    try {
      for (let i = 0; i < files.length; i++) {
        const file: File = files[i];
        const fileName = file.name;
        const allowedExtensions = ['mp3', 'wav'];
        const fileExtension = fileName.split('.').pop();

        // check if file at least 1MB in size
        if (file.size < 1000000) {
          throw new Error(file.name + ' error, Each file size should be least 1MB');
        }

        if (!fileName) {
          throw new Error('File name not found');
        }

        if (typeof fileName !== 'string') {
          throw new Error('File name is not a string');
        }

        // check if file extension is other than mp3 or wav
        if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
          throw new Error(fileName + ' error, Only mp3 and wav files are allowed');
        }

        const token = await user.getIdToken();

        const { preSignedUrl, url } = await getPresignedURL({
          token,
          fileInfo: {
            ext: fileExtension,
            contentType: file.type,
          },
        });

        await uploadToS3({
          preSignedUrl,
          file,
          setUploadProgress: (progress) => {
            setUploadedHfFiles((prevFiles) =>
              prevFiles.map((file, index) => (index === i ? { ...file, progress, url } : file))
            );
          },
        });
      }

      setNotification({
        status: NOTIFICATION_TYPE_SUCCESS,
        message: 'Files uploaded successfully',
      });
    } catch (error: unknown) {
      setNotification({
        message: (error as Error)?.message ?? 'Something went wrong',
        status: NOTIFICATION_TYPE_ERROR,
      });
    } finally {
      setIsUploadingHfFile(false);
    }
  };

  useEffect(() => {
    setIsDisabled(
      !speakerName ||
        uploadedHfFiles.length < 1 ||
        isUploadingHfFile ||
        isSubmitting ||
        (hasMultipleSpeakers && !clippings) ||
        (hasMultipleSpeakers && !hasMinimumClippings(uploadedHfFiles, clippings)) ||
        !consent
    );
  }, [speakerName, uploadedHfFiles, isUploadingHfFile, isSubmitting, hasMultipleSpeakers, clippings, consent]);

  const onPrevious = () => {
    switch (step) {
      case STEP.INSTANT_CLONE_FINISH:
        updateParams({ originalSample: '', originalSampleFileName: '' });
        deleteFile();
        setStep(STEP.INSTANT_CLONE_METHOD);
        break;
      case STEP.HIGH_FIDELITY_CLONE_FINISH:
        setUploadedHfFiles([]);
        setStep(STEP.HIGH_FIDELITY_CLONE_METHOD);
        setSpeakerName('');
        break;
      case STEP.RECORDING:
        setStep(STEP.RECORD);
        stopRecording();
        break;
      case STEP.RECORD:
        setStep(STEP.INSTANT_CLONE_METHOD);
        break;
      case STEP.INSTANT_CLONE_METHOD:
        setStep(STEP.INPUT_LANGUAGE);
        break;
      case STEP.HIGH_FIDELITY_CLONE_METHOD:
        setStep(STEP.INPUT_LANGUAGE);
        break;
      case STEP.INPUT_LANGUAGE:
        setStep(STEP.VOICE_CLONE_TYPE);

        break;
      case STEP.VOICE_CLONE_TYPE:
        break;
      default:
        console.warn('Unhandled step:', step);
    }
  };

  const onStop = () => {
    stopRecording();
    setStep(STEP.INSTANT_CLONE_FINISH);
  };

  const onRestart = () => {
    startRecording();
  };

  const onRecord = () => {
    setStep(STEP.RECORD);
  };

  const onStartRecording = () => {
    setStep(STEP.RECORDING);
    startRecording();
  };

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateParams({ name: event.target.value });
  };

  const onGenderChange = (gender: VoiceGender) => {
    updateParams({ gender });
  };

  const onChangeLanguage = (language: Language) => {
    updateParams({ language });
  };

  const handleSendRequest = () => {
    if (!params.name) {
      setNotification({ status: 'error', message: 'Please name your voice clone.' });
      return;
    }

    if (!params.originalSample) {
      setNotification({ status: 'error', message: 'The voice sample needs to be uploaded before proceeding.' });
      return;
    }

    if (voiceCloneFiles) {
      finishVoiceCloning({ voiceId: voiceCloneFiles.originalVoiceId, ...params });
    } else {
      generateVoiceCloneFiles(params.originalSample);
    }
  };

  return {
    step,
    setStep,
    isRecording,
    setVoiceCloningType,
    onPrevious,
    onRecord,
    onInstant,
    uploadedSampleFile: uploadedSampleFile ? [uploadedSampleFile] : [],
    uploadFile,
    updateParams,
    deleteFile,
    onFileDelete,
    onFilesSelected,
    consent,
    setConsent,
    isGeneratingVoiceCloneFiles,
    handleSendRequest,
    isFinishingVoiceCloning,
    params,
    closeModal,
    onNameChange,
    onGenderChange,
    openModal,
    onStartRecording,
    onStop,
    onRestart,
    recordingTime,
    audioStream,
    onHighFidelity,
    uploadHfFile,
    uploadedHfFiles,
    handleDeleteAudio,
    speakerName,
    setSpeakerName,
    hasMultipleSpeakers,
    setHasMultipleSpeakers,
    submit,
    setClippings,
    clippings,
    isDisabled,
    ar_epochs,
    diff_epochs,
    isSubmitting,
    setArEpochs,
    setDiffEpochs,
    modal,
    getOpenSetter,
    instantCloningExceeded,
    voiceCloningType,
    hfCloningExceeded,
    onChangeLanguage,
    onDefineLang,
  };
};

export default useCreateVoiceCloneWizard;
